<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.edit') }} {{ $t('app.equipos.single') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="observer"
          v-slot="{ validate, reset }"
        >
          <form>
            <v-list
              three-line
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.sucursales.single') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.sucursales.single')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.sucursal_id"
                        :placeholder="$t('app.sucursales.single')"
                        :items="sucursales"
                        item-text="nombre"
                        item-value="id"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.name') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.name')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.nombre"
                        :placeholder="$t('app.headers.name')"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.logo') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <base-file-input
                      :placeholder="$t('app.headers.logo')"
                      icon="mdi-image"
                      accept="image/*"
                      @change="onChangeControl"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="jugadores.length > 0">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('app.jugadores.name') }}

                    <v-btn
                      small
                      outlined
                      @click="item.jugadores.push({jugador_id: null, posicion_id: null, capitan: false})"
                    >
                      <v-icon left>
                        mdi-plus-circle
                      </v-icon>

                      {{ $t('app.buttons.add') }}
                    </v-btn>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row
                      v-for="(jugador, index) in item.jugadores"
                      :key="`jugador-${index}`"
                    >
                      <v-col
                        class="d-flex flex-grow-1 flex-shrink-0"
                      >
                        <v-autocomplete
                          v-model="item.jugadores[index].jugador_id"
                          :disabled="jugadores.length === 0"
                          :placeholder="$t('app.jugadores.name')"
                          :items="jugadores"
                          item-text="persona.fullname"
                          item-value="id"
                        >
                          <template v-slot:item="data">
                            <v-list-item-avatar>
                              <img :src="data.item.persona.fotografia">
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title v-text="data.item.persona.fullname" />
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        class="d-flex flex-grow-1 flex-shrink-0"
                      >
                        <v-autocomplete
                          v-model="item.jugadores[index].posicion_id"
                          :items="posiciones"
                          item-text="nombre"
                          item-value="id"
                          :placeholder="$t('app.posiciones.single')"
                        />
                      </v-col>
                      <v-col
                        class="d-flex flex-shrink-1 flex-grow-0"
                      >
                        <v-switch
                          v-model="item.jugadores[index].capitan"
                          class="pl-5"
                          :placeholder="$t('app.headers.captain')"
                          :label="$t('app.headers.captain') + ': ' + (item.jugadores[index].capitan ? $t('app.general.yes') : $t('app.general.no'))"
                        />
                      </v-col>
                      <v-col
                        class="d-flex flex-shrink-1 flex-grow-0"
                      >
                        <v-btn
                          icon
                          @click="item.jugadores.splice(index, 1)"
                        >
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'EquiposEdit',
    data () {
      return {
        dialog: false,
        item: {
          nombre: '',
          sucursal_id: null,
          logo: false,
          jugadores: [],
        },
        sucursales: [],
        datePickerModalStart: false,
        datePickerModalEnd: false,
        jugadores: [],
        posiciones: [],
      }
    },
    mounted () {
      EventBus.$on('equipos-edit', async (item) => {
        this.toggleLoader()
        this.sucursales = await this.getBranches()
        this.jugadores = await this.getJugadores()
        this.posiciones = await this.getPosiciones()
        this.item = item

        this.item.logo = false
        this.item.jugadores = item.jugadores.map(jugador => jugador.pivot)

        this.toggleLoader()
        this.dialog = true
      })
    },
    methods: {
      onChangeControl (files) {
        if (files && files.length > 0) {
          this.item.logo = files[0]
        }
      },
      async saveItem () {
        this.$refs.observer.validate()
          .then(async success => {
            if (!success) return

            this.toggleLoader()

            const formData = new FormData()
            formData.append('id', this.item.id)
            formData.append('nombre', this.item.nombre)
            formData.append('sucursal_id', this.item.sucursal_id)

            for (const index in this.item.jugadores) {
              const player = this.item.jugadores[index]
              formData.append(`jugadores[${index}][jugador_id]`, player.jugador_id)
              formData.append(`jugadores[${index}][posicion_id]`, player.posicion_id)
              formData.append(`jugadores[${index}][capitan]`, player.capitan)
            }

            if (this.item.logo) {
              formData.append('logo', this.item.logo)
            }

            await this.$http.post(route('v1/team/' + this.item.id), formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
              .then(response => {
                if (response.body.code === 200) {
                  this.processSuccess(response)
                  this.dialog = false
                  EventBus.$emit('reload-items')
                } else {
                  this.processError(response)
                }
              }, error => {
                this.processError(error)
              })

            this.toggleLoader()
          })
      },
    },
  }
</script>

<style scoped>

</style>
